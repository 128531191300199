import { Modal } from "antd";
import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";

function DeleteModal({ deleteModal, setDelete }) {
  const language = useSelector((state) => state.language.lang);
  const deleteFunction = async () => {
    await axios.post("https://api.manjam.shop/v4/delete", {
      table: deleteModal?.table,
      column: deleteModal?.column,
      column_id: deleteModal?.column_id,
    });
    setDelete(false)
    if (deleteModal?.getFunction) deleteModal?.getFunction();
  };
  return (
    <Modal
      open={deleteModal}
      onCancel={() => setDelete(false)}
      onOk={() => deleteFunction()}
    >
      <h1>{language == "ar" ? "هل أنت متأكد ؟" : "Are You Sure ?"}</h1>
    </Modal>
  );
}

export default DeleteModal;
