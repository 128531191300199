export const colors = [
    { color_ar: "أسود", color_en: "Black", color_code: "#000000" },
    { color_ar: "أبيض", color_en: "White", color_code: "#FFFFFF" },
    { color_ar: "أحمر", color_en: "Red", color_code: "#FF0000" },
    { color_ar: "أخضر", color_en: "Green", color_code: "#008000" },
    { color_ar: "أزرق", color_en: "Blue", color_code: "#0000FF" },
    { color_ar: "أصفر", color_en: "Yellow", color_code: "#FFFF00" },
    { color_ar: "سماوي", color_en: "Cyan", color_code: "#00FFFF" },
    { color_ar: "أرجواني", color_en: "Magenta", color_code: "#FF00FF" },
    { color_ar: "رمادي", color_en: "Gray", color_code: "#808080" },
    { color_ar: "برتقالي", color_en: "Orange", color_code: "#FFA500" },
    { color_ar: "بني", color_en: "Brown", color_code: "#A52A2A" },
    { color_ar: "وردي", color_en: "Pink", color_code: "#FFC0CB" },
    { color_ar: "بنفسجي", color_en: "Purple", color_code: "#800080" },
    { color_ar: "ليموني", color_en: "Lime", color_code: "#00FF00" },
    { color_ar: "زيتي", color_en: "Olive", color_code: "#808000" },
    { color_ar: "كحلي", color_en: "Navy", color_code: "#000080" },
    { color_ar: "خمري", color_en: "Maroon", color_code: "#800000" },
    { color_ar: "فيروزي", color_en: "Teal", color_code: "#008080" },
    { color_ar: "فضي", color_en: "Silver", color_code: "#C0C0C0" },
    { color_ar: "ذهبي", color_en: "Gold", color_code: "#FFD700" },
    { color_ar: "متعدد الألوان", color_en: "Multicolor", color_code: "N/A" }
  ];
  