import { Icon } from "@iconify/react";
import React from "react";
import WeakFilter from "../../pages/products/Filters/WeakFilter";

const Filter = () => {
  return (
    <div className="filter">
      <div className="filter_head">
        {
          // <WeakFilter />
        }
      </div>
    </div>
  );
};

export default Filter
 