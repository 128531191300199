import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function ProductPreview({
  addedProduct,
  ColorsArr,
  properties,
  language,
  offerData,
}) {
  // State for product tabs
  const [productTabs, setProductTabs] = useState(1);
  const [productSize, setProductSize] = useState(null);
  const [newPrice, setNewPrice] = useState(null);

  const handleChangeValChecked = (itemId, valueId) => {
    // Logic to handle changing the selected value
  };

  return (
    <div>
      <div style={{ marginTop: "30px" }} className="product_details">
        <div className="swiper_time">
          <Swiper
            breakpoints={{
              0: { slidesPerView: 1, spaceBetween: 10 },
              480: { slidesPerView: 1, spaceBetween: 10 },
              768: { slidesPerView: 1, spaceBetween: 15 },
              1024: { slidesPerView: 1, spaceBetween: 15 },
              1280: { slidesPerView: 1, spaceBetween: 20 },
            }}
            slidesPerView={1}
            spaceBetween={10}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            {addedProduct?.imagesUrls?.length > 0
              ? addedProduct.imagesUrls.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                  >
                    <div style={{ height: "100%" }} className="product_img">
                      <img src={item} alt="product" />
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
        {/* <div class="price_container buy_with_no_tender">
          <div class="price-section">
            <span class="price-label">
              {language == "ar" ? "السعر" : "Price"}
            </span>
            <span class="price-value">9000</span>
          </div>
          <button class="buy-button">
            <i class="gavel-icon">⚒️</i>
            <span> {language == "ar" ? "اشتري الان" : "BUY NOW"}</span>
          </button>
        </div> */}
        {/* Product Tabs */}
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "40px",
              margin: "auto",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <p
                onClick={() => setProductTabs(1)}
                style={
                  productTabs === 1 ? { color: "black" } : { color: "#ddd" }
                }
              >
                {language === "ar" ? "وصف" : "Description"}
              </p>
              {productTabs === 1 && (
                <div
                  style={{
                    backgroundColor: "#7f19a8",
                    margin: "0px",
                    padding: "0px",
                    borderRadius: "5px",
                    width: "60px",
                    height: "3px",
                  }}
                ></div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <p
                onClick={() => setProductTabs(2)}
                style={
                  productTabs === 2 ? { color: "black" } : { color: "#ddd" }
                }
              >
                {language === "ar" ? "تفاصيل المنتج" : "Product Details"}
              </p>
              {productTabs === 2 && (
                <div
                  style={{
                    backgroundColor: "#7f19a8",
                    borderRadius: "7px",
                    width: "60px",
                    height: "3px",
                  }}
                ></div>
              )}
            </div>
          </div>

          <div style={{ width: "100%" }}>
            {productTabs === 1 && (
              <div>
                <p dangerouslySetInnerHTML={{__html: language === "ar"
                    ? addedProduct.description_ar
                    : addedProduct.description}}>
                  
                </p>
              </div>
            )}

            {productTabs === 2 && (
              <div style={{ width: "100%" }}>
                {ColorsArr[0]?.ColorProperities?.map((item, index) => {
                  if (item?.Properity_values?.length > 0) {
                    return (
                      <div
                        key={item.id}
                        className="productsize"
                        style={{ borderBottom: "1px solid #ddd" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100% !important",
                          }}
                        >
                          <h6 style={{ color: "#9b9b9b" }}>
                            {language === "ar" ? item.label_ar : item.label}
                          </h6>
                          {item.Properity_values.map((it, ind) => (
                            <div
                              key={ind}
                              className="size"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                             
                              <label htmlFor={it.label}>
                                {language === "ar" ? it.label_ar : it.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}

                <div className="productsize">
                  {/* <div
                    className="sizes"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "15px",
                    }}
                  >
                    <h6 style={{ color: "#9b9b9b" }}>
                      {language === "ar"
                        ? "شركة الشحن خلال 4 أيام"
                        : "Shipping Within 4 days"}
                      <span></span> (
                      {offerData?.shippingStatus ? "Free Delivery" : "15 AED"})
                    </h6>
                    <p style={{ color: "black", fontWeight: "bold" }}>
                      {language === "ar" ? "شحن" : "shipping"}
                    </p>
                  </div> */}

                  {addedProduct.model_number !== null && (
                    <div
                      className="product_model"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <h6 style={{ color: "#9b9b9b" }}>{"Brand Name"}</h6>
                      <p style={{ color: "black", fontWeight: "bold" }}>
                        {addedProduct.model_number}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPreview;
