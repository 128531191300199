import React, { useState, useEffect } from "react";
import { DatePicker, Input, Select, Checkbox } from "antd";
import moment from "moment";
import { Icon } from "@iconify/react";
import axios from "axios";
import { toast } from "react-toastify";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DateTimePicker } from '@mui/x-date-pickers';
// import AdapterMoment from '@mui/x-date-pickers/AdapterMoment';

const UpdateOfferModal = ({
  visible,
  offerData,
  onClose,
  onUpdateSuccess,
  language,
}) => {
  const [formData, setFormData] = useState({});
  const [isTendered, setIsTendered] = useState(false);
  const [rateTime, setRateTime] = useState(1);
  const [tenderEndTime, setTenderEndTime] = useState(15);
  const [addloading, setAddLoading] = useState(false);

  useEffect(() => {
    if (offerData) {
      setFormData({ ...offerData });
      setIsTendered(offerData.isTendered === 1);
      setRateTime(offerData.rate_time || 1);
      setTenderEndTime(offerData.tenderEndTime || 15);
    }
  }, [offerData]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = formData.varients ? [...formData.varients] : [];
    updatedVariants[index] = { ...updatedVariants[index], [field]: value };
    setFormData((prev) => ({ ...prev, varients: updatedVariants }));
  };

  const sendUpdateRequest = () => {
    setAddLoading(true);
    const formattedVariants = formData.varients
      .map(
        (variant) =>
          `${variant.color_id}*${variant.prop_id}*${variant.prop_value_id}*${
            variant.av_quantity || 0
          }*${variant.old_price || 0}*${variant.new_price || 0}*${
            variant.min_price || 0
          }*${variant.rate_money || 0}`
      )
      .join("***");

    const data = {
      id: formData.id,
      product_id: formData.product_id,
      store: formData.store?.join("***"),
      varients: formattedVariants,
      will_av_after: formData.will_av_after,
      will_av_for: formData.will_av_for,
      shippingStatus: formData.shippingStatus ? 1 : 0,
      isTendered: isTendered ? 1 : 0,
      maximunJoiners: formData.maximunJoiners,
      cost: formData.cost,
      tenderEndTime: tenderEndTime,
      rate_time: rateTime > 3 ? rateTime / 1000 : rateTime,
    };

    axios
      .post("https://api.manjam.shop/v4/offers/edit", data)
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Offer Updated Successfully");
          onUpdateSuccess();
          onClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => console.error("Update Failed:", error))
      .finally(() => setAddLoading(false));
  };

  return visible ? (
    <div className="edit_model add_model from_Shipping_div">
      <div
        className="closeModel"
        onClick={onClose}
        style={{ cursor: "pointer", fontSize: "24px" }}
      >
        <Icon icon="line-md:close-small" />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendUpdateRequest();
        }}
        className="formaddproduct offerformproduct"
      >
        <label>
          {language === "ar"
            ? "المتجر المتاح فيه العرض"
            : "Warehouse (The store where the offer is available)"}
        </label>
        <label>{language === 'ar' ? 'المتجر' : 'Store'}</label>
<select
  value={formData.store ? formData.store[0] : ""}
  onChange={(e) => handleInputChange("store", [e.target.value])}
  style={{ width: "100%", marginBottom: "10px" }}
>
  <option value="uae">{language === "ar" ? "الإمارات" : "UAE"}</option>
  <option value="ksa">{language === "ar" ? "السعودية" : "Saudi Arabia"}</option>
</select>


        <label>{language === "ar" ? "معرف المنتج" : "Item/code"}</label>
        <Input
          placeholder={
            language === "ar"
              ? "إذا بحثت برقم المنتج أضف علامة # قبلها"
              : "If you search by product number, add a # sign before it"
          }
          type="text"
          value={formData.product_id}
          disabled
        />

        <div className="d-flex">
          <label>{language === "ar" ? "ضمن المناقصة" : "Is Tendered"}</label>
          <Checkbox
            checked={isTendered}
            onChange={(e) => setIsTendered(e.target.checked)}
          />
        </div>

        <h3>{language === "ar" ? "التفاصيل المتاحة" : "Available Variants"}</h3>
        {formData.varients &&
          formData.varients.map((variant, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <h4>
                {language === "ar"
                  ? `متغير ${index + 1}`
                  : `Variant ${index + 1}`}
              </h4>
              <label>
                {language === "ar" ? "الكمية المتاحة" : "Available Quantity"}
              </label>
              <Input
                type="number"
                value={variant.av_quantity || 0}
                onChange={(e) =>
                  handleVariantChange(index, "av_quantity", e.target.value)
                }
              />
            <label>{language === "ar" ? "MSRP" : "MSRP"}</label>
              <Input
                type="number"
                value={variant.old_price || 0}
                onChange={(e) =>
                  handleVariantChange(index, "old_price", e.target.value)
                }
              />
              <label>{language === "ar" ? "السعر القديم" : "Listed Price"}</label>
              <Input
                type="number"
                value={variant.new_price || 0}
                onChange={(e) =>
                  handleVariantChange(index, "new_price", e.target.value)
                }
              />
              <label>
                {language === "ar" ? "الحد الأدنى للسعر" : "Base Price"}
              </label>
              <Input
                type="number"
                value={variant.min_price || 0}
                onChange={(e) =>
                  handleVariantChange(index, "min_price", e.target.value)
                }
              />
              <label>{language === "ar" ? "معدل التراجع" : "Rate Money"}</label>
              <Input
                type="number"
                value={variant.rate_money || 0}
                onChange={(e) =>
                  handleVariantChange(index, "rate_money", e.target.value)
                }
              />
            </div>
          ))}

        {isTendered && (
          <>
            <label>{language === "ar" ? "معدل الزمن" : "Rate Time"}</label>
            <select
              value={rateTime > 3 ? rateTime / 1000 : rateTime}
              onChange={(e) => setRateTime(parseInt(e.target.value))}
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <option value={1}>1s</option>
              <option value={2}>2s</option>
              <option value={3}>3s</option>
            </select>

            <label>
              {language === "ar" ? "مدة المناقصة" : "Tender Duration"}
            </label>
            <select
              value={tenderEndTime}
              onChange={(e) => setTenderEndTime(parseInt(e.target.value))}
              style={{
                width: "100%",
                marginBottom: "10px",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              <option value={15}>15s</option>
              <option value={30}>30s</option>
              <option value={60}>60s</option>
            </select>
          </>
        )}

        <label>
          {language === "ar" ? "سوف يتم توفيره فى خلال" : "Scheduale Deal"}
        </label>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            value={
              formData.will_av_after ? moment(formData.will_av_after) : null
            }
            onChange={(e) => handleInputChange("will_av_after", e?._d)}
          />
        </LocalizationProvider>

        {!isTendered && (
          <>
            <label>
              {language === "ar" ? "سوف يتم توفيره لوقت" : "Will Available For"}
            </label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                value={
                  formData.will_av_for ? moment(formData.will_av_for) : null
                }
                onChange={(e) => handleInputChange("will_av_for", e?._d)}
              />
            </LocalizationProvider>
          </>
        )}

        <button type="submit" className="btn btn-primary" disabled={addloading}>
          {addloading ? "Loading..." : language === "ar" ? "تحديث" : "Update"}
        </button>
      </form>
    </div>
  ) : null;
};

export default UpdateOfferModal;
